<template>
    <html>
      <body class="" style="background: #FFF">
        <HeaderMobil :localNavigation="true" v-on:go-to-back="changeGoToBack" />
        <MenuLateralMobile/>
        <div class="h-screen grid grid-cols-6 grid-rows-12 grid-flow-col">
           <MenuLateral/>
            <div class="bg-container col-span-6">
                <div class="w-full md:w-4/5 mx-auto h-auto py-16 grid grid-cols-1 md:grid-cols-2">
                    <div class="px-10 md:px-5 lg:px-20">
                        <p class="text-3xl font-bold mb-10 RobotoBold">Bienvenida</p>
                        <p class="text-lg font-light mb-14 description"> ¡Bienvenido a CABSA SEGUROS!<br> Realizaremos una serie de preguntas para hacer cotización de seguros.</p>
                        <input type="button" class="bg-mustardDark cursor-pointer text-white px-5 py-2 mx-auto my-5 w-full md:w-full h-14 md:h-14  rounded text-center font-medium text-xl" @click="next" value="Continuar">  
                    </div>
                    <div class="px-10 py-16 hidden md:block">
                        <img :src="require('@/assets/Cuenta/vidaSimple/hello.svg')" alt="FONCABSA" class="mx-auto">
                    </div>
                </div>
            </div>
        </div>
      </body>
    </html>
</template>

<script>
import { onBeforeMount, ref } from 'vue';
import { useRouter } from 'vue-router';
import MenuPrincipal from "@/components/Cuenta/MenuPrincipal.vue";
import Questions from "@/components/Cuenta/Quote/QuoteQuestions.vue";
import HeaderMobil from "@/components/Cuenta/HeaderMobil.vue";
import MenuLateralMobile from '@/components/MenuLateralMobile.vue';;
import MenuLateral from '@/components/LateralMenu.vue';;
import Footer from '@/components/Footer.vue';

export default {
    setup(){
        const router = useRouter();
        const category = ref('');
        let counterGoBack = ref(0);
        let continueQuote = ref(false);

        onBeforeMount(()=>{
            category.value = router.currentRoute.value.params.category;
        });

        const changeGoToBack = () => {
            counterGoBack.value = counterGoBack.value+1;
            console.log("goToBack", counterGoBack.value)
        }

        const next = () => {
            router.push({path:"/cuenta/cotizacion/vida-simple"});
        }
        
        return {
            category,
            changeGoToBack,
            counterGoBack,
            continueQuote,
            next
        }
    },
    components:{
        MenuPrincipal,
        HeaderMobil,
        MenuLateralMobile,
        MenuLateral,
        Footer,
        Questions
    }
}
</script>

<style scoped>
.description {
    font-family: 'roboto-regular';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 33px;
    color: #485462;
}
</style>